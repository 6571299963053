$("[data-quant-change]").click(function(event) {
	event.preventDefault();

	var evento = $(this).data("quant-change"),
		alvo = $(this).attr("href"),
		valor = parseInt($( alvo ).val() || 0);


		if ( evento == "plus") {
			var novoValor = valor + 1;
			$(alvo).val( novoValor ).trigger('change');
		}else{
			var novoValor = valor - (valor == 0 ? 0 : 1);
			$(alvo).val( novoValor ).trigger('change');
		}
});