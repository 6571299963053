$('[data-toggle="menu"]').click(function(){
	$('.topo-content').addClass('active');
});

$('.bg-topo').click(function(){
	$('.topo-content').removeClass('active');
});

$(document).click(function(){
	$('.todas-categorias').removeClass('active')
})

$('button.ctrls').click(function(e){
	var w = window.innerWidth;

	e.stopPropagation();

	if(w <= 768){
		$('#categorias').collapse('toggle');
	}

	if ( (w>=992) || (w<=1119) ){
		$('.todas-categorias').toggleClass('active');
		$(this).toggleClass('active')
	}
});